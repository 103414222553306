import './index.scss'

import projects from '../../projects'
import Project from './project';

const Portfolio = () => {
    return (
        <div className="portfolio">
            <div className="container">
                <div className="text-zone">
                    <h2 className="title">My Latest Projects</h2>
                    <p>Here are some of my latest projects that I worked on recently</p>
                </div>
                <div className="projects">
                    {projects.slice(0, 3).map((project) =>
                        <Project key={project.id} project={project}/>
                    )}
                </div>
                <div class="more-projects">
                    <a href={`/projects`} class="link">More Projects</a>
                </div>
            </div>

        </div>
    );
};

export default Portfolio;