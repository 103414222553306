import './index.scss'
import TextTransition, { presets } from "react-text-transition";
import { useState, useEffect } from 'react';

const TEXTS = [
    "Web Developer",
    "Designer",
    "Instructor",
  ];

const Home = () => {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() =>
            setIndex(index => index + 1),
            3000 // every 3 seconds
        );
        return () => clearTimeout(intervalId);
    }, []);

    return (<div className="home">
        <div className="content container">
            <span className="secondary-text">Welcome to my reality</span>
            <h2 className="title">Hi, I am Marian</h2>
            <h2 className="title experience">
                <TextTransition direction="up" springConfig={presets.gentle}>
                    {TEXTS[index % TEXTS.length]}
                </TextTransition></h2>
            <h2 className="title">From Romania.</h2>
        </div>
    </div>)
};

export default Home;