import { useEffect, useState } from 'react';
import Modal from '../Modal/Modal'

const Project = ({project}) => {
    const [open, setOpen] = useState(false)

    const openModal = (projectToSee) => {
        setOpen(true);
    }

    let hash = window.location.hash ?? ''


    useEffect(() => {
        if (hash && hash === "#project" + project.id) {
            setOpen(true)
        }
    }, [hash])



    return (
        <>
            <div onClick={() => openModal(project)} className="project" style={{backgroundImage: `url("${project.image}")`}}>
                <div className="overlay">
                </div>
                <div className="overlay-details">
                    <span className="technology">{project.technology}</span>
                    <h3 className="title">{project.title}</h3>
                    <span className="link-button">View Details</span>
                </div>
            </div>
            <Modal show={open} handleClose={() => setOpen(false)}>
                <h3 className='project-title'>{project.title}</h3>
                <div className='project-image-wrapper'>
                    <img src={project.image} alt="" className='project-modal-image'/>
                </div>
                <div className='categories modal-project-categories'>
                    {project.categories.map((category, index) => (
                         <span key={index} className={category}>{category}</span>
                    ))}
                </div>
                <h3 className='project-description-title'>Description </h3>
                <p className='project-description'>{project.description}</p>
                <h3 className='project-demo-link'><a href={project.link} target='_blank' className='project-link'>View demo</a></h3>
            </Modal>
        </>
        );
}
 
export default Project;