import { faBars, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import "./index.scss";

import { Link } from "react-scroll";
import {
    faFacebook,
  faGithub,
  faLinkedin,
  faTwitter
} from "@fortawesome/free-brands-svg-icons";

const Header = () => {
    const [mobileMenu, setMobileMenu] = useState(false);

    const handleScroll = () => {
        const header = document.querySelector('.header');
        const scrollY = window.scrollY;

        if (scrollY  < 70 ) {
            header.classList.remove('onscreen');
        }

        if (scrollY  > 70 ) {
            header.classList.add('onscreen');
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, [handleScroll]);

    const toggleMobileMenu = () => {
        setMobileMenu(!mobileMenu);
    };

    const hideMobileMenu = () => {
      if (mobileMenu) {
        setMobileMenu(false);
      }
    };

    return (<header className="header">
        <div className="content container">
            <a href="/" className="brand">LevelCoding</a>
            <nav className="menu" style={{ top: mobileMenu ? "0" : "-100%" }}>
            <div className="menu__content">
              <button type="button" class="hideMenu">
                <FontAwesomeIcon icon={faClose} onClick={toggleMobileMenu} />
              </button>
              <Link
                to="home"
                activeClass="active"
                smooth
                spy
                isDynamic
                className="menu-link"
                onClick={hideMobileMenu}
              >
                <span>Home</span>
                <span class="link-underline"></span>
              </Link>
              <Link
                to="about"
                activeClass="active"
                spy
                smooth
                isDynamic
                className="menu-link"
                onClick={hideMobileMenu}
              >
                <span>About</span>
                <span class="link-underline"></span>
              </Link>
              <Link
                to="services"
                activeClass="active"
                smooth
                spy
                isDynamic
                className="menu-link"
                onClick={hideMobileMenu}
              >
                <span>Services</span>
                <span class="link-underline"></span>
              </Link>
              <Link
                to="projects"
                activeClass="active"
                smooth
                spy
                isDynamic
                className="menu-link"
                onClick={hideMobileMenu}
              >
                <span>Portfolio</span>
                <span class="link-underline"></span>
              </Link>
              <Link
                to="hire"
                activeClass="active"
                smooth
                spy
                isDynamic
                className="menu-link"
                onClick={hideMobileMenu}
              >
                <span>Contact</span>
                <span class="link-underline"></span>
              </Link>
            </div>
          </nav>
          <nav className="social">
            <a href="https://github.com/pirvanm" target="_blank">
                <FontAwesomeIcon icon={faGithub} />
            </a>
            <a href="https://ro.linkedin.com/in/marian-pirvan-a182ab95/en?trk=public_post_feed-actor-name" target="_blank">
                <FontAwesomeIcon icon={faLinkedin} />
            </a>
            <a href="https://www.facebook.com/marian.pirvan" target="_blank">
                <FontAwesomeIcon icon={faFacebook} />
            </a>
          </nav>
            <button type="button" className="buttonShowMenu" onClick={toggleMobileMenu}>
                <FontAwesomeIcon icon={faBars} />
            </button>
        </div>
    </header>)
};

export default Header;