import { faFigma } from '@fortawesome/free-brands-svg-icons';
import { faBook, faKey, faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './index.scss'

const Services = () => {
    return (<div className="services">
        <div className="container">
            <div className="text-zone">
                <h2 className="title">Services</h2>
            </div>
            <div className="services-list">
                <div className="service">
                    <FontAwesomeIcon icon={faFigma} />
                    <h3>Web Design</h3>
                    <p>
                        I value clean structure and clean design patterns, and thoughtful interactions.
                    </p>
                    <h3>I enjoy doing</h3>
                    <p>
                        UI, Web, Apps, Photos, Logos
                    </p>
                    <h3>Tools I use</h3>
                    <p>
                        Photoshop, Illustrator, Affinity, Figma, Pen & Paper
                    </p>
                </div>
                <div className="service">
                    <FontAwesomeIcon icon={faWrench}/>
                    <h3>Web Development</h3>
                    <p>
                       Clean code is my motto and design patterns are my guiding lines.
                    </p>
                    <h3>I enjoy doing</h3>
                    <p>
                       Web Apps, APIs
                    </p>
                    <h3>Tools I use</h3>
                    <p>
                        Laravel, Node, Express, Docker, React, Vuejs
                    </p>
                </div>
                <div className="service">
                <FontAwesomeIcon icon={faBook}/>
                    <h3>Instructor</h3>
                    <p>
                        Training students into the world of web development and web design.
                    </p>
                    <h3>I enjoy doing</h3>
                    <p>
                        Web Training, Courses and much more
                    </p>
                    <h3>Tools I use</h3>
                    <p>
                        Trello, Youtube, Quizz Apps, Code Playgrounds
                    </p>
                </div>
            </div>
        </div>
    </div>)
};

export default Services;