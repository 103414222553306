import React, { useCallback } from 'react';
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import './App.scss';
import particlesOptions from "./particles.js";

import Header from './components/Header'
import Home from './components/Home';
import About from './components/About/indexs';
import Services from './components/Services';
import Hire from './components/Hire';
import Portfolio from './components/Portfolio';
import Footer from './components/Footer';

function App() {
    const particlesInit = useCallback(main => {
        loadFull(main);
    }, [])

    return (
        <div className="App">
            <Particles options={particlesOptions} init={particlesInit}/>
            <Header />
            <Home />
            <About />
            <Services />
            <div id="projects"><Portfolio /></div>
            <div id="hire"><Hire /></div>
            <Footer />
        </div>
    );
}

export default App;
