import './index.scss'

import { useEffect, useState } from 'react';

import projects from '../../projects';
import Footer from '../../components/Footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faBackward } from '@fortawesome/free-solid-svg-icons';
import Project from '../../components/Portfolio/project';

const categories = ['All', 'PHP', 'Javascript', 'html/css3', 'Laravel', 'VueJs','NuxtJs', 'Inertia', 'Typescript', 'Flutter/Dart', 'React', 'NextJs', 'Stripe', 'Prismadb'];

const Projects = () => {
    const [currentCategory, setCurrentCategory] = useState('all');
    const [filteredProjects, setFilteredProjects] = useState(projects)
    
    const setActiveCategory = (category) => {
       let found = categories.find((c) => c.toLocaleLowerCase() === category.toLocaleLowerCase());

       if (!found) {
        console.log(`Category ${category} can not be found.`);
        return;
       }

       setCurrentCategory(category);
    };

    useEffect(() => {
        if (currentCategory.toLocaleLowerCase() === 'all') {
            setFilteredProjects(projects);
            return;
        }

        let myFilteredProjects = projects.filter(p => {
            let filtered = p.categories.filter(c => c.toLocaleLowerCase() ===  currentCategory.toLocaleLowerCase());

            return filtered.length > 0;
        });

        setFilteredProjects(myFilteredProjects);

    }, [currentCategory]);

    return (<><div className="portfolio">
    <div className="container">
        <div className="text-zone">
            <a href="/" className="back-link"><FontAwesomeIcon icon={faArrowAltCircleLeft} /></a>
            <h2 className="title">My Projects</h2>
            <p>These are some of the projects I've worked on</p>
        </div>
        <div className="categories">
            {categories.map((category, index) => 
                <span onClick={() => setActiveCategory(category)} key={index} className={`${currentCategory.toLocaleLowerCase() === category.toLocaleLowerCase() ? 'active' : ''}`}>
                    { category }
                </span>
            )}
        </div>
        <div className="projects">
            {filteredProjects.map((project, index) =>
                <Project key={project.id} project={project} />
            )}
        </div>
    </div>
</div><Footer/></>)
};

export default Projects;