const projects = [
    {
        id: 1,
        categories: ['react', 'nextjs', 'typescript', 'prismadb', 'stripe'],
        technology: 'NextJs/React/Stripe',
        title: 'Web Development',
        description: 'A very entry "level" shop for people who want to sell only a particular type of goods, for example, shoes, paintings, t-shirts and so on.',
        image: '/nextjs-react-shop.png',
        link: 'https://nextjs-shop-kohl.vercel.app/',
    },
    {
        id: 2,
        categories: ['php', 'laravel', 'vuejs', 'javascript','nuxtjs'],
        technology: 'Laravel/VueJs/NuxtJs',
        title: 'Web Development',
        description: 'Music application that uses YT API to collect and group music genres under a label, giving you the ability to find the music you need at any particular time',
        image: '/chill-project.png',
        link: 'https://chillwhispers.com/',
    },
    {
        id: 3,
        categories: ['php', 'laravel', 'vuejs', 'typescript', 'inertia'],
        technology: 'Laravel/Inertia/Typescript',
        title: 'Web Development',
        description: 'An application build with Laravel, Inertia, Vue3 and Typescript to create roadmaps meant to help a developer learn by following a given path with all the information needed',
        image: '/roadmaps.png',
        link: 'https://roadmaps.levelcoding.com/',
    },
    {
        id: 4,
        categories: ['HTML/CSS3'],
        technology: 'Figma/HTML/CSS3',
        title: 'Web Design',
        description: 'A simple web design page about a car rent company',
        image: '/cars-layout-project.png',
        link: 'https://levelcoding.com/projects/cars/index.html',
    },
    {
        id: 5,
        categories: ['figma', 'HTML/CSS3'],
        technology: 'Figma/HTML/CSS3',
        title: 'Web Design',
        description: 'A simple web design page about a digital library',
        image: '/library-project.png',
        link: 'https://levelcoding.com/projects/library/index.html',
    },
    {
        id: 6,
        categories: ['flutter/dart'],
        technology: 'Flutter/Dart/Spotify',
        title: 'Mobile App',
        description: 'Mobile application related with the chill application. Built with React Native and making use of APIs for interacting with the chill application',
        image: '/mobile-app.jpg',
        link: 'https://levelcoding.com',
    },
];
export default projects;