import './index.scss'
import { faBootstrap, faCss3, faFigma, faHtml5, faJs, faLaravel, faLinux, faNode, faPhp, faReact, faVuejs, faWordpress } from '@fortawesome/free-brands-svg-icons';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const About = () => {
    return (<div className="about">
        <div className="container">
            <div className="text-zone">
                <h2 className="title">About Me</h2>
                <p className="about-text">
                    I started my journey more than 10 years ago and since then I've worked with many companies. I've done remote work
                    for agencies and exchanged thoughts and work experience on projects dealing with digital content for both 
                    business and consumer use. I am perpetually working to improve my skills, making good use of my adaptability
                    on dealing with new projects and ideas.
                </p>
            </div>
            <div className="skills">
                <div className="flex">
                    <FontAwesomeIcon icon={faPhp}/>
                    <FontAwesomeIcon icon={faDatabase}/>
                    <FontAwesomeIcon icon={faJs}/>
                    <FontAwesomeIcon icon={faCss3}/>
                </div>
                <div className="flex">
                    <FontAwesomeIcon icon={faNode}/>
                    <FontAwesomeIcon icon={faReact}/>
                    <FontAwesomeIcon icon={faLaravel}/>
                    <FontAwesomeIcon icon={faVuejs}/>
                </div>
                <div className="flex">
                    <FontAwesomeIcon icon={faFigma}/>
                    <FontAwesomeIcon icon={faLinux}/>
                    <FontAwesomeIcon icon={faBootstrap}/>
                    <FontAwesomeIcon icon={faWordpress}/>
                </div>
            </div>
        </div>
    </div>)
};

export default About;