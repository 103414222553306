import { faFacebook, faGithub, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './index.scss'

const Footer = () => {
    return (<footer class="footer">
        <div class="container">
            <a href="/" className="brand">LevelCoding</a>
            <nav className="social">
                <a href="https://github.com/pirvanm" target="_blank">
                    <FontAwesomeIcon icon={faGithub} />
                </a>
                <a href="https://ro.linkedin.com/in/marian-pirvan-a182ab95/en?trk=public_post_feed-actor-name" target="_blank">
                    <FontAwesomeIcon icon={faLinkedin} />
                </a>
                <a href="https://www.facebook.com/marian.pirvan" target="_blank">
                    <FontAwesomeIcon icon={faFacebook} />
                </a>
          </nav>
          <span class="rights">&copy; All rights reserved 2023.</span>
        </div>
    </footer>)
};

export default Footer;