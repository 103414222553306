import './index.scss'

import { useRef } from 'react'
import UseEmail from '../../hooks/useEmail';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Hire = () => {
    const refForm = useRef();

    const {loading, submitted, error, sendEmail} = UseEmail('https://public.herotofu.com/v1/7c285550-22e4-11ee-8b54-f37b8ac0c701')

    const sendEmailRequest = (e) => {
        e.preventDefault();
        const name = document.querySelector('#name');
        const email = document.querySelector('#email');
        const subject = document.querySelector('#subject');
        const message = document.querySelector('#message');
        const botPrevention = document.querySelector('#a_password');
        let securityPass = true;



        if (botPrevention.value) {
            securityPass = false;
            return;
        }

        const messages = [
            {
                text: 'How much is 12 + 24 ?',
                answer: '36',
            },
            {
                text: 'How many hours in one day?',
                answer: '24',
            }
        ];

        const securityMessage = messages[1];

        let confirmMessage = prompt(securityMessage.text);

        if (!confirmMessage || confirmMessage === '' || confirmMessage != securityMessage.answer) {
            securityPass = false;
            return;
        }

        if (name.value.trim() === '' || email.value.trim() === '' || subject.value.trim() === '' || message.value.trim() === '') {
            alert('Please fill all fields.');
        }

        if (securityPass) {
            sendEmail({
                name: name.value,
                email: email.value,
                subject: subject.value,
                message: message.value,
            })

            toast('Thank you for your message!', {
                theme: 'dark',
            });
            name.value = '';
            email.value = '';
            subject.value = '';
            message.value = '';
        }
    
    }

    return (<div className="hire">
        <div className="container">
            <div className="text-zone">
                <h2 className="title">Hire Me.</h2>
                <p className="text">I am readily available for work, full time, partime and freelance work. You can connect with me via email:</p>
            </div>
            <div className="hire-zone">
                <form ref={refForm} onSubmit={sendEmailRequest} className="hire-form" autoComplete="off">
                    <div className="form-group two-inputs">
                        <input type="text" name="name" placeholder="Your Name" id="name"/>
                        <input type="text" name="email" placeholder="Your Email" id="email"/>
                    </div>
                    <div className="form-group">
                        <input type="text" name="subject" placeholder="Subject" id="subject"/>
                    </div>
                    <div className="form-group">
                        <textarea name="message" placeholder="Your message" id="message"></textarea>
                    </div>
                    <input type="text" name="a_password" id="a_password" style={{display: "none"}} tabIndex="-1" autoComplete="off"/>
                    <div className="form-group form-button">
                        <button disabled={loading} type="submit">Send</button>
                    </div>
                </form>
            </div>
        </div>
        <ToastContainer />
    </div>)
};

export default Hire;